<mat-card [mediaClasses]="['push-sm']" flex tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="{{modelName}}-loader">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row">
          <h3 class="push-top-none">
            {{(this.form.controls.name.value ? this.form.controls.name.value : 'name')|translate}}
          </h3>
        </div>
        <div flex="100" layout="row">
          <div class="left_form" flex="50" layout="column">
              <mat-form-field flex="100">
                <input (change)="(formErrors.name ? this.validateFormField(this.form,
                        'name') : '')" formControlName="name"
                       matInput
                       placeholder="{{'name'|translate|ucFirst}}" type="text" value="{{data.name}}">
                <mat-hint align="start" class="warning">
                    <span [hidden]="!formErrors.name" class="tc-red-600">
                      <span>{{ formErrors.name }}</span>
                    </span>
                </mat-hint>
              </mat-form-field>
          </div>
          <div flex="50" layout="column">
            <mat-form-field flex="100">
              <input (change)="(formErrors.website ? this.validateFormField(this.form,
                        'website') : '')" formControlName="website"
                     matInput
                     placeholder="{{'website'|translate|ucFirst}}" type="text" value="{{data.website}}">
              <mat-hint align="start" class="warning">
                    <span [hidden]="!formErrors.website" class="tc-red-600">
                      <span>{{ formErrors.website }}</span>
                    </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div layout="row">
          <div class="left_form" flex="50" layout="column">
            <mat-form-field flex="100">
              <input (change)="(formErrors.email ? this.validateFormField(this.form,
                    'email') : '')" formControlName="email"
                     matInput
                     placeholder="{{'service_emailaddress'|translate|ucFirst}}" type="text" value="{{data.email}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.email" class="tc-red-600">
                  <span>{{ formErrors.email }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="phonenumber-pane" flex="50" layout="column">
            <div class="phoneNumberField push-right {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex>
              <ngx-intl-tel-input
                [cssClass]="'phoneNumberInput'"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [maxLength]="15"
                [phoneValidation]="true"
                [searchCountryField]="(SearchCountryField ? [SearchCountryField.Iso2, SearchCountryField.Name] : '')"
                [searchCountryFlag]="true"
                [selectFirstCountry]="false"
                [selectedCountryISO]="(this.data.country === 'EN'? 'GB' : this.data.country)"
                flex
                formControlName="phoneNumber"
                name="phoneNumber">
              </ngx-intl-tel-input>

              <mat-hint align="start" class="warning">
                    <span [hidden]="!formErrors.phoneNumber" class="tc-red-600">
                      <span>{{ formErrors.phoneNumber }}</span>
                    </span>
              </mat-hint>
            </div>
          </div>
        </div>

        <div layout="row">
          <div flex="50" layout="column">
            <mat-form-field [style.width.%]="100" class="left_form" flex="100">
              <mat-select class="row-select" formControlName="currency"
                          placeholder="{{'currency'|translate|ucFirst}}">
                <mat-option *ngFor="let currency of currencies"
                            [value]="currency.alphaCode">{{currency.name}} ({{currency.symbol}})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div flex="50" layout="column">
            <mat-form-field [style.width.%]="100" flex="100">
              <mat-select class="row-select" formControlName="timezone"
                          placeholder="{{'timezone'|translate|ucFirst}}">
                <mat-option *ngFor="let timezone of timezones"
                            [value]="timezone">{{timezone}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div layout="row">
          <div flex="50" layout="column">
            <mat-form-field [style.width.%]="100" class="left_form" flex="100">
              <input (change)="(formErrors.address ? this.validateFormField(this.form,
                      'address') : '')" formControlName="address"
                     matInput
                     placeholder="{{'address'|translate|ucFirst}}" type="text" value="{{data.address}}">
              <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors.address" class="tc-red-600">
                    <span>{{ formErrors.address }}</span>
                  </span>
              </mat-hint>
            </mat-form-field>
          </div>
          <div flex="50" layout="row">
            <mat-form-field flex="100">
              <input (change)="(formErrors.addressExtra ? this.validateFormField(this.form,
                    'addressExtra') : '')" formControlName="addressExtra"
                     matInput
                     placeholder="{{'addressextra'|translate|ucFirst}}" type="text" value="{{data.addressExtra}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.addressExtra" class="tc-red-600">
                  <span>{{ formErrors.addressExtra }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div layout="row">
          <div flex="50" layout="column">
            <mat-form-field [style.width.%]="100" class="left_form" flex="100">
              <input (change)="(formErrors.city ? this.validateFormField(this.form,
                    'city') : '')" formControlName="city"
                     matInput
                     placeholder="{{'city'|translate|ucFirst}}" type="text" value="{{data.city}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.city" class="tc-red-600">
                  <span>{{ formErrors.city }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div flex="50" layout="column">
            <div [style.width.%]="100" class="right_form" flex="100">
              <mat-form-field [style.width.%]="50" class="left_form" flex="50">
                <mat-select class="row-select" formControlName="country"
                            placeholder="{{'country'|translate|ucFirst}}">
                  <mat-option *ngFor="let country of countries"
                              [value]="country.alpha2Code">{{country.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field [style.width.%]="50" class="right_form" flex="50">
                <mat-select class="row-select" formControlName="language"
                            placeholder="{{'account_language'|translate|ucFirst}}">
                  <mat-option *ngFor="let language of languages"
                              [value]="language.short">{{language.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div layout="row">
          <div flex="50" layout="column">
            <div [style.width.%]="100" class="left_form" flex="100">
              <mat-form-field [style.width.%]="50" class="left_form" flex="50">
                <input (change)="(formErrors.vatPercentage ? this.validateFormField(this.form,
                        'vat_percentage') : '')" formControlName="vatPercentage"
                       matInput
                       placeholder="{{'vat_percentage'|translate|ucFirst}}" type="number">
                <mat-hint align="start" class="warning">
                        <span [hidden]="!formErrors.vatPercentage" class="tc-red-600">
                          <span>{{ formErrors.vatPercentage }}</span>
                        </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field [style.width.%]="50" class="right_form" flex="50">
                <input (change)="(formErrors.settlementVatPercentage ? this.validateFormField(this.form,
                        'settelement_vat_percentage') : '')" formControlName="settlementVatPercentage"
                       matInput
                       placeholder="{{'settelement_vat_percentage'|translate|ucFirst}}" type="number">
                <mat-hint align="start" class="warning">
                        <span [hidden]="!formErrors.settlementVatPercentage" class="tc-red-600">
                          <span>{{ formErrors.settlementVatPercentage }}</span>
                        </span>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div flex="50" layout="column">
            <div [style.width.%]="100" class="left_form" flex="100">
              <mat-form-field [style.width.px]="200" class="left_form">
                <mat-select class="row-select" formControlName="vatType"
                            placeholder="{{'vat_type'|translate|ucFirst}}">
                  <mat-option *ngFor="let vatType of vatTypes"
                              [value]="vatType.value"><img *ngIf="(vatType.country && vatType.country.flag)"
                                                           class="flag"
                                                           src="{{(vatType.country ? vatType.country.flag : '')}}">
                    {{(vatType.country ? vatType.country.alpha2Code : '')}}
                    {{vatType.type.toUpperCase()}} - {{(vatType.country ? vatType.country.name : '')}}
                  </mat-option>
                  <mat-select-trigger class="pull-top">
                    <img class="flag" src="{{getSelectedVAT('flag')}}"> {{getSelectedVAT('value')}}
                  </mat-select-trigger>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="vatNumberField">
                <input (change)="(formErrors.vatNumber ? this.validateFormField(this.form,
                      'vatNumber') : '')" formControlName="vatNumber"
                       matInput
                       placeholder="{{'vat_number'|translate|ucFirst}}" type="text" value="{{data.vatNumber}}">
                <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors.vatNumber" class="tc-red-600">
                    <span>{{ formErrors.vatNumber }}</span>
                  </span>
                </mat-hint>
              </mat-form-field>

              <a class="yda-link-blue" href="https://stripe.com/docs/billing/customer/tax-ids"
                 target="_new">{{'read_more'|translate|ucFirst}}</a>
            </div>
          </div>
        </div>

        <div [formGroup]="form.controls['settings']" class="left_form" flex="50" layout="row">
          <div flex="33" layout="column">
            <mat-form-field [style.width.%]="100" class="left_form" flex="100">
              <mat-select class="row-select" flex="100" formControlName="dateFormat"
                          placeholder="{{'date_format'|translate|ucFirst}}">
                <mat-option *ngFor="let format of dateFormats"
                            [value]="format">{{format}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div flex="50" layout="column">
            <mat-form-field [style.width.%]="100" class="left_form" flex="100">
              <mat-select class="row-select" flex="100" formControlName="clock"
                          placeholder="{{'clock_format'|translate|ucFirst}}">
                <mat-option *ngFor="let format of clockFormats"
                            [value]="format">{{format + '_hour_format' | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div flex="33" layout="column">
            <mat-form-field [style.width.%]="100" class="right_form" flex="100">
              <mat-select class="row-select" flex="100" formControlName="distanceFormat"
                          placeholder="{{'setting_distance_format'|translate|ucFirst}}">
                <mat-option *ngFor="let format of distanceFormats"
                            [value]="format">{{'distance_unit_' + format | translate|ucFirst}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="saveCompany()"
            [disabled]="this.data.protected"
            color="accent"
            flex
            mat-raised-button>{{this.action + '_' + modelName + '_button'|translate|ucFirst}}</button>
    <span *ngIf="this.data.protected" class="protected_account">{{'protected_account' | translate | ucFirst}}</span>
    <button (click)="updateType('business')" *ngIf="this.data.type==='dispatch'"
            color="accent"
            flex
            mat-raised-button>{{'update_dispatch_type_business_button'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card>

<mat-card flex tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <app-google-api-key [company]="this.data" flex="100" layout="row"></app-google-api-key>
  </mat-card-content>
</mat-card>

<mat-card flex tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="stripe-integration">
      <form *ngIf="form" [formGroup]="form">
        <div *ngIf="!this.data.stripeAccountId" class="not_connected">
          <mat-icon>power_off</mat-icon>
          <mat-label>{{'not_connected'|translate|ucFirst}}</mat-label>
        </div>

        <div *ngIf="this.data && this.data.stripeAccountId" class="connected">
          <mat-icon>power_on</mat-icon>
          <mat-label>{{'connected'|translate|ucFirst}}</mat-label>
        </div>

        <h3 class="push-top-none">
          {{'payment_processing'|translate|ucFirst}}
        </h3>
        <mat-card-subtitle class="stripe_subtitle">{{'stripe_subtext'|translate|ucFirst}}</mat-card-subtitle>

        <div *ngIf="this.data.stripeAccountId" class="mat-content" flex="100">
          <div class="stripe_account_details">
            <strong>{{'stripe_account_id'|translate|ucFirst}}:</strong> {{this.data.stripeAccountId}}
          </div>

          <div class="stripe_account_links">
            <a class="yda-link-blue" href="https://dashboard.stripe.com"
               target="_blank">{{'login_to_stripe'|translate|ucFirst}}</a>
            <span> | </span>
            <a class="yda-link" href="javascript:return false;" (click)="disconnectStripeAccount()"
                 target="_blank">{{'disconnect'|translate|ucFirst}}</a>
          </div>
        </div>

        <button (click)="startStripeIntegration()" *ngIf="!this.data.stripeAccountId"
                color="accent"
                flex
                mat-raised-button>{{'connect_stripe'|translate|ucFirst}}</button>
      </form>
    </ng-template>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="formCalendar && this.googleConnected" [formGroup]="formCalendar" [mediaClasses]="['push-sm']" flex
          tdMediaToggle="gt-xs">
  <mat-card-content class="pull-bottom">
    <h3 class="push-top-none">
      {{'calendar'|translate|ucFirst}}
    </h3>

    <div class="connected_google_header" flex="100">{{'connected_google_account'|translate|ucFirst}}</div>

    <div class="connected-calendar" flex="50" layout="row">
      <img class="google-calendar-logo" src="assets/img/google-calendar-logo.png">
      <div flex="100">
        <div flex="100">{{this.connectedGoogleAccount}}</div>
      </div>
      <mat-icon (click)="disconnectGoogleAccount()">delete</mat-icon>
    </div>
    <div *ngIf="this.googleConnected" class="options">
      <mat-list-item class="mat-checkbox-pane" flex="100" layout="row">
        <mat-checkbox [checked]="(this.form && this.formCalendar.controls['checkConflicts'].value)"
                      class="pull-left"
                      formControlName="checkConflicts" value="true">
          <mat-label>{{this.localTranslation['onboard_calendar_option_1']|ucFirst}}</mat-label>
        </mat-checkbox>
      </mat-list-item>

      <mat-list-item flex="100" layout="row">
        <mat-checkbox [checked]="(this.form &&
        this.formCalendar.controls['addEvents'].value)" class="pull-left" formControlName="addEvents" value="true">
          <mat-label>{{(this.connectedCalendar === 'new' ? this.localTranslation['onboard_calendar_option_3'] :
            this.localTranslation['onboard_calendar_option_2'])|ucFirst}}</mat-label>
        </mat-checkbox>
        <button (click)="openSelectCalendar()" *ngIf="this.googleConnected" class="edit-button"
                mat-stroked-button>{{'edit'|translate|ucFirst}}</button>
      </mat-list-item>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="form" class="push-left">
    <button (click)="saveCalendar()" color="accent"
            mat-raised-button>
      {{'save' | translate | ucFirst}}
    </button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="formCalendar &&
   !this.googleConnected" [formGroup]="formCalendar" [mediaClasses]="['push-sm']" flex tdMediaToggle="gt-xs">
  <mat-card-content class="pull-bottom">
    <h3 class="push-top-none">
      {{'calendar'|translate|ucFirst}}
    </h3>

    <mat-card-subtitle class="pull-left">{{'connect_google_account_explanation' | translate |
      ucFirst}}</mat-card-subtitle>
  </mat-card-content>

  <mat-card-actions *ngIf="form" class="push-left">
    <button (click)="_globalFunctions.handleAuthClick()" color="accent"
            mat-raised-button>
      {{'connect_google_calendar' | translate | ucFirst}}
    </button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="!this.googleConnected">
  <app-ics-calender [id]="this.data.id"
                    [type]="'company'" flex></app-ics-calender>
</mat-card>

<mat-card *ngIf="(userIsOwner || user.ydaSupportUser)
    && this.data && !this.data.ActiveContract" [mediaClasses]="['push-sm']" flex tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="clost-account">
      <form *ngIf="form" [formGroup]="form">
        <h3 class="push-top-none">
          {{'delete_fleet'|translate|ucFirst}}
        </h3>
        <mat-card-subtitle class="stripe_subtitle">{{'close_profile_text'|translate|ucFirst}}</mat-card-subtitle>

        <button (click)="startCloseProfile()"
                color="warn"
                flex
                mat-raised-button>{{'delete_fleet'|translate|ucFirst}}</button>
      </form>
    </ng-template>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="this.data
  && this.data.ActiveContract" [mediaClasses]="['push-sm']" flex tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="clost-account">
      <form *ngIf="form" [formGroup]="form">
        <h3 class="push-top-none">
          {{'delete_fleet'|translate|ucFirst}}
        </h3>
        <mat-card-subtitle
          class="stripe_subtitle">{{'close_profile_active_contract_text'|translate|ucFirst}}</mat-card-subtitle>
      </form>
    </ng-template>
  </mat-card-content>
</mat-card>
