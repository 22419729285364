<div class="filter push-left-sm push-right-sm">
  <div class="drop-downs">
    <mat-form-field ngClass="push-sm push-bottom-none">
      <mat-select (selectionChange)="onYearChanged($event)" [(ngModel)]="filterData.year"
                  placeholder="{{'year'|translate|ucFirst}}">
        <mat-option *ngFor="let year of yearFilterValues" [value]="year">
          {{year}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field ngClass="push-sm push-bottom-none">
      <mat-select (selectionChange)="onFromChanged($event)" [value]="1" placeholder="{{'from'|translate|ucFirst}}">
        <mat-option *ngFor="let month of monthFilterValues; let i = index;" [value]="(i+1)">
          {{month|ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field ngClass="push-sm push-bottom-none">
      <mat-select (selectionChange)="onToChanged($event)" [value]="12" placeholder="{{'to'|translate|ucFirst}}">
        <mat-option *ngFor="let month of monthFilterValues; let i = index;" [value]="(i+1)">
          {{month|ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="type==='history'" ngClass="push-sm push-bottom-none">
      <mat-select (selectionChange)="onStatusChanged($event)" [value]="'all'"
                  placeholder="{{'status'|translate|ucFirst}}">
        <mat-option *ngFor="let invoiceStatus of invoiceStatusses; let i = index;" [value]="invoiceStatus">
          {{(invoiceStatus == 'final' ? 'unpaid' : invoiceStatus)|translate|ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <td-search-box (searchDebounce)="onSearchChanged($event)" [alwaysVisible]="true"
                 class="push-sm push-bottom-none search-bar" placeholder="{{'search_here'|translate}}"></td-search-box>
</div>
