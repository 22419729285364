import {Component, Input, OnInit} from '@angular/core';
import moment from 'moment';
import {Company} from '../../../../../models/company';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-ride-time-data',
  templateUrl: './ride-time-data.component.html',
  styleUrls: ['./ride-time-data.component.scss']
})
export class RideTimeDataComponent implements OnInit {
  @Input() status: string;
  @Input() created: Date;
  @Input() requestedDate: Date;
  @Input() pickupDate: Date;
  @Input() completed: Date;
  @Input() canceled: Date;
  @Input() rideEvents: any;
  expanded = false;
  company: Company;
  totalJobTime: any;

  constructor(
    private route: ActivatedRoute,
  ) {
    const {company} = this.route.parent.snapshot.data;
    this.company = company;
  }

  ngOnInit() {
    const start = (this.rideEvents && this.rideEvents.started ? moment(this.rideEvents.started.date) : null);
    const end = (this.rideEvents && this.rideEvents.completed ? moment(this.rideEvents.completed.date) : null);

    const {company} = this.route.parent.snapshot.data;
    this.company = company;

    if (this.rideEvents && this.rideEvents.started && this.rideEvents.completed) {
      const difference = (this.rideEvents && this.rideEvents.started && this.rideEvents.completed
        ? end.diff(start)
        : '00:00:00');
      const duration = moment.duration(difference);
      const hours = Math.floor(duration.asHours());
      this.totalJobTime = (hours < 10 ? `0${hours}` : hours) + moment.utc(difference).format(':mm:ss');
      //
      // console.log('start', start);
      // console.log('end', end);
      // console.log('totalJobTime', this.totalJobTime);
    }

  }

}
