import {Component, EventEmitter, Input, isDevMode, OnChanges, OnInit, Output} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {DateAdapter} from '@angular/material/core';
import {TdMediaService} from '@covalent/core/media';

declare function getUserLocale(): any; // just change here from arun answer.

interface FilterData {
  filterSearchTerm: string;
  filterStatus: string;
  filterDates: {
    from: any,
    to: any
  }
}

@Component({
  selector: 'app-driver-rides-filter',
  templateUrl: './ride-filter.component.html',
  styleUrls: ['./ride-filter.component.scss']
})
export class RideFilterComponent implements OnInit, OnChanges {

  @Input() rideStatuses: string[] = [];
  @Input() defaultStatus = 'all';
  @Input() defaultFromDate: Date;
  @Input() defaultToDate: Date;
  @Input() showExport: boolean;
  @Input() showSearchFilter = true;
  @Input() showStatusFilter: boolean;
  @Input() title = '';
  @Input() exportType = 'jobs';

  @Output() filterChanged = new EventEmitter<FilterData>();
  @Output() export = new EventEmitter<boolean>();

  data: FilterData = {
    filterSearchTerm: '',
    filterStatus: 'completed',
    filterDates: {
      from: '',
      to: ''
    }
  };


  constructor(
    public media: TdMediaService,
    private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale(getUserLocale());
    this.data.filterStatus = this.defaultStatus;
  }

  ngOnInit() {
    if (this.defaultFromDate) {
      this.data.filterDates.from = this.defaultFromDate;
    }
    if (this.defaultToDate) {
      this.data.filterDates.to = this.defaultToDate;
    }
  }

  doExport() {
    this.export.emit(true);
  }

  ngOnChanges(data) {
    if (isDevMode()) {
      console.log(this.defaultFromDate);
    }
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    this.data.filterDates.from = this.defaultFromDate;
  }

  onSearchTermChanged(searchTerm: string): void {
    if (isDevMode()) {
      console.group('onSearchTermChanged()');
      console.log('searchTerm:', searchTerm);
      console.groupEnd();
    }
    this.data.filterSearchTerm = searchTerm;
    this.filterChanged.emit(this.data);
  };

  onStatusChanged(change: MatSelectChange): void {
    if (isDevMode()) {
      console.group('onStatusChanged()');
      console.log('status:', change.value);
      console.groupEnd();
    }
    this.data.filterStatus = change.value;
    this.filterChanged.emit(this.data);
  }

  onFromFilterChanged(date: Date): void {
    if (isDevMode()) {
      console.group('onFromFilterChanged()');
      console.log('date:', date);
      console.groupEnd();
    }
    this.data.filterDates.from = date;
    this.filterChanged.emit(this.data);
  }

  onToFilterChanged(date: Date): void {
    if (isDevMode()) {
      console.group('onToFilterChanged()');
      console.log('date:', date);
      console.groupEnd();
    }

    if (date) {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);

      this.data.filterDates.to = date;
      this.filterChanged.emit(this.data);
    }
  }
}
