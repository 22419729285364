<app-no-contract *ngIf="(this.context==='company' &&
  (
    !this.company.ActiveContract ||
    !['active','past_due'].includes(this.company.ActiveContract.foreignStatus)
  ) &&
  (!this.company.hasDispatcherLicense || (this.company && this.company.ActiveContract.foreignStatus !== 'active')) &&
  this.company.type === 'business')"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card [class]="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due'].includes(this.company.ActiveContract.foreignStatus)) && (!this.company.hasDispatcherLicense || (this.company && this.company.ActiveContract.foreignStatus !== 'active'))  &&
this.company.type === 'business' ?
'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <app-driver-rides-filter (export)="export()"
                           (filterChanged)="rideFilterChanged($event)"
                           [defaultFromDate]="defaultFilterFromDate"
                           [defaultStatus]="defaultFilterStatus"
                           [rideStatuses]="rideStatuses"
                           [showExport]="true"
                           [showStatusFilter]="true"
                           [title]="'my_jobs'"></app-driver-rides-filter>
  <mat-divider></mat-divider>

  <ng-template tdLoading="{{loaderName}}">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" selectedIndex="0">
      <mat-tab *ngIf="(this.context === 'driver')">
        <ng-template mat-tab-label>
          <mat-icon>call_received</mat-icon>
          {{'list_rides_as_driver'|translate|ucFirst}}
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sortTableData($event, 'ridesAsDriver')"
                *ngFor="let c of meAsDriverColumns"
                [active]="(ridesAsDriver.sortBy == c.name)"
                [name]="c.name"
                [numeric]="(!!c.numeric)"
                [sortOrder]="(ridesAsDriver.sortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="(!!c.sortable)"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="ridesAsDriver.filteredData.length > 0">
          <tr (click)="open(item.id)" *ngFor="let item of ridesAsDriver.filteredData" td-data-table-row>
            <td *ngFor="let c of meAsDriverColumns" [numeric]="c.numeric" class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'requestedDate'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                <span *ngSwitchCase="'departure'"><span *ngIf="item[c.name]" class="wrap-line"
                                                        title="{{item.departure}}">{{item.departure}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'destination'"><span *ngIf="item[c.name]" class="wrap-line"
                                                          title="{{item.destination}}">{{item.destination}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'status'"><span
                  *ngIf="item[c.name]">{{'ride_status_' + item[c.name] | translate |ucFirst}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'fareAmount'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'driverPrice'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'driverSettlement'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'commissionAmount'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                  *ngIf="!item[c.name]">-</span>
                </span>
              </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="ridesAsDriver['filteredData'].length === 0">
          <tr>
            <td [colSpan]="meAsOwnerColumns.length"
                class="table-placeholder">
              {{'no_jobs_in_overview'|translate|ucFirst}}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #myRidesPagingBar (change)="pageTableData($event, 'ridesAsDriver')" [pageSize]="ridesAsDriver.pageSize"
                       [total]="ridesAsDriver.filteredTotal">
          <span hide-xs>{{'rows_p_page'|translate}}:</span>
          {{myRidesPagingBar.range}} <span hide-xs>of {{myRidesPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template *ngIf="(this.context==='driver')" mat-tab-label>
          <mat-icon>call_made</mat-icon>
          {{'list_rides_as_owner'|translate|ucFirst}}
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sortTableData($event, 'ridesAsOwner')"
                *ngFor="let c of meAsOwnerColumns"
                [active]="(ridesAsOwner.sortBy == c.name)"
                [name]="c.name"
                [numeric]="(!!c.numeric)"
                [sortOrder]="(ridesAsOwner.sortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="(!!c.sortable)"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="ridesAsOwner['filteredData'].length > 0">
          <tr *ngFor="let item of ridesAsOwner['filteredData']" td-data-table-row>
            <td (click)="(c.name !== 'actions' ? open(item.id) : '')" *ngFor="let c of meAsOwnerColumns" [numeric]="c.numeric"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'requestedDate'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                <span *ngSwitchCase="'departure'"><span *ngIf="item[c.name]" class="wrap-line max-width-300"
                                                        title="{{item.departure}}">{{item.departure}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'destination'">
                  <span *ngIf="item[c.name]" class="wrap-line max-width-300" title="{{item.destination}}">
                    {{item.destination}}</span>
                  <span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'status'"><span
                  *ngIf="item[c.name]">{{'ride_status_' + item[c.name] | translate |ucFirst}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'fareAmount'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'driverPrice'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'driverSettlement'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'commissionAmount'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
              </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="ridesAsOwner['filteredData'].length === 0">
          <tr>
            <td [colSpan]="meAsOwnerColumns.length"
                class="table-placeholder">
              {{'no_jobs_in_overview'|translate|ucFirst}}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #sentRidesPagingBar (change)="pageTableData($event, 'ridesAsOwner')" [pageSize]="ridesAsOwner.pageSize"
                       [total]="ridesAsOwner.filteredTotal">
          <span hide-xs>{{'rows_p_page'|translate}}:</span>
          {{sentRidesPagingBar.range}} <span hide-xs>{{'of' | translate}} {{sentRidesPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
