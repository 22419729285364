import {Component, Input, OnInit} from '@angular/core';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {RideService} from '../../../../../services/ride.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-ride-passenger-data',
  templateUrl: './ride-passenger-data.component.html',
  styleUrls: ['./ride-passenger-data.component.scss']
})
export class RidePassengerDataComponent implements OnInit {
  @Input() passengerCount = 1;
  @Input() passenger: any = {};
  @Input() flightNumber: string;
  @Input() rideId: string;
  expanded = false;
  name = '-';
  context = 'company';
  translations: string[];

  constructor(
    public snackBar: MatSnackBar,
    private _rideService: RideService,
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
    private _route: ActivatedRoute) {
    this.context = this._route.routeConfig['context'] || 'driver';

    _translateService.get(['confirmation_email_resend', 'ok', 'confirm_resend_email', 'yes', 'no'
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit() {
    if (!this.passenger) {
      this.passenger = {
        fname: '-'
      };
    }
  }

  resendConfirmation() {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_resend_email'],
      disableClose: false,
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._rideService.sendConfirmationEmail(self.rideId, self.context).subscribe(() => {
          setTimeout(function () {
            self.snackBar.open(ucFirst(self.translations['confirmation_email_resend'].replace('{{email}}', self.passenger.email)), self.translations['ok'], {
              duration: 3000
            });
          }, 500);
        }, error => {
          console.error(error);
        })
      }
    });
  }
}
