import {AfterViewInit, Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../../../../../../../models/company';
import {ThemePalette} from '@angular/material/core';
import moment from 'moment/moment';
import {InvoiceFilterComponent} from '../../../../../invoices/invoice-filter/invoice-filter.component';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats} from '@angular-material-components/datetime-picker';

var CUSTOM_MOMENT_FORMATS;


const setTime = (date: Date, time: string) => {
  const parts = time.split(':');
  date.setHours(parseInt(parts[0], 10));
  date.setMinutes(parseInt(parts[1], 10));
  return date;
}

const getTime = (date: Date) => {
  const h = date.getHours();
  const m = date.getMinutes();
  const toStringRep = (int) => int < 10 ? '0' + int : '' + int;
  return toStringRep(h) + ':' + toStringRep(m);
}

export class CustomDateFormat {
  format = 'YYYY-MM-DD HH:mm';
  constructor() { }

  get display() {
    return {
      dateInput: this.format,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'L',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  }

  get parse() {
    return { dateInput: this.format ? 'l, LTS' : 'l' }
  }
}

@Component({
  selector: 'app-ride-edit-requesteddate',
  templateUrl: './ride-edit-requesteddate.component.html',
  styleUrls: ['./ride-edit-requesteddate.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useClass: CustomDateFormat }
  ]
})
export class RideEditRequesteddateComponent implements AfterViewInit {
  @ViewChild('picker') myPicker: any;
  @Output() newRequestedDateEvent = new EventEmitter<string>();
  @Output() newBookedDurationEvent = new EventEmitter<string>();
  @Input() requestedDate: Date;
  @Input() requestedDateTime: string;
  @Input() form: UntypedFormGroup;
  @Input() formErrors: any;
  company: Company;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: string = moment().subtract(5, 'years').format('YYYY-MM-DD');
  public maxDate: string = moment().add(4, 'years').format('YYYY-MM-DD');
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  hasErrors = false;

  constructor(
    @Inject(NGX_MAT_DATE_FORMATS) public config: CustomDateFormat,
    private route: ActivatedRoute,
  ) {
    const {company} = this.route.parent.snapshot.data;
    this.company = company;

    this.config.format = this.company.dateFormat + ', HH:mm';
  }

  ngAfterViewInit() {

  }

  setRequestedDateTime() {
    if (this.company && this.company.dateFormat) {
      this.requestedDateTime = moment(this.requestedDate).format(this.company.dateFormat + 'THH:mm');
    } else {
      this.requestedDateTime = moment(this.requestedDate).format('YYYY-MM-DDTHH:mm');
    }
  }

  setRequestedDate($event) {
    const requestedDate = moment(this.form.controls['requestedDate'].value).toISOString();
    this.newRequestedDateEvent.emit(requestedDate);
  }

  bookedDurationChange() {
    // @ts-ignore
    this.newBookedDurationEvent.emit(this.form.controls['priceMeta'].controls['bookedDuration'].value);
  }
}
