import {Injectable} from '@angular/core';
import XLSX from 'xlsx/dist/xlsx.mini.min';
import {ucFirst} from '../pipes/uc-first.pipe';
import {GlobalService} from './global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';

@Injectable()
export class ExporterService extends GlobalService {

  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('exports');
  }

  generate(fileName: string, translations: any[], sheets: any[]): void {
    // console.log(`[ExporterService.generate]: translations`, translations);
    // console.log(`[ExporterService.generate]: sheets`, sheets);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();

    sheets.forEach(sheet => {
      const sheetData = [];
      const headers = [];

      sheet.headers.forEach(header => headers.push(ucFirst(`${translations[header.label] || '-'}`)));
      sheetData.push(headers);

      sheet.rows.forEach(row => {
        const newRow = [];
        sheet.headers.forEach(header => newRow.push(ucFirst(`${row[header.name] || ''}`)));
        sheetData.push(newRow);
      });

      const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workBook, workSheet, ucFirst(`${translations[sheet.title]}`));
    });

    XLSX.writeFile(workBook, `${fileName}.xlsx`);
  }

}
