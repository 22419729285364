<app-invoice-filter #invoiceFilter (filterChanged)="invoiceFilterChanged($event)"
                    (searchChanged)="search($event)"
                    [context]="context"
                    [filterData]="filterData"
                    [type]="type">
</app-invoice-filter>

<mat-divider></mat-divider>

<div [@multiSelect]="multiSelectedState" class="multi-select-buttons">
  <button (click)="finalizeSelectedInvoices('invoice_process', this.multiSelect['draft'].length)" *ngIf='draftSelected'
          color="accent"
          mat-raised-button>
    {{ 'update_process_invoice' | translate | ucFirst}} ({{this.multiSelect['draft'].length}})
  </button>

  <button (click)="multiAction('delete_draft', this.multiSelect['draft'].length)" *ngIf='draftSelected' color="accent"
          mat-raised-button>
    {{ 'delete_draft_invoices' | translate | ucFirst}} ({{this.multiSelect['draft'].length}})
  </button>

  <button (click)="resendInvoices((this.multiSelect['paid'].length + this.multiSelect['final'].length))"
          *ngIf='(paidSelected || finalSelected)' color="accent"
          mat-raised-button>
    {{ 'invoices_send_email' | translate | ucFirst}}
    ({{(this.multiSelect['paid'].length + this.multiSelect['final'].length)}})
  </button>

  <button
    (click)="multiAction('invoice_create_credit', (this.multiSelect['paid'].length + this.multiSelect['final'].length))"
    *ngIf='(paidSelected || finalSelected)' color="accent"
    mat-raised-button>
    {{ 'invoice_create_credit' | translate | ucFirst}}
    ({{(this.multiSelect['paid'].length + this.multiSelect['final'].length)}})
  </button>

  <button (click)="multiAction('invoice_unpaid', this.multiSelect['paid'].length)" *ngIf='paidSelected' color="accent"
          mat-raised-button>
    {{ 'update_invoices_set_unpaid' | translate | ucFirst}} ({{this.multiSelect['paid'].length}})
  </button>

  <button (click)="multiAction('invoice_paid', this.multiSelect['final'].length)" *ngIf='finalSelected' color="accent"
          mat-raised-button>
    {{ 'update_invoices_set_paid' | translate | ucFirst}} ({{this.multiSelect['final'].length}})
  </button>

  <button (click)="downloadInvoices()" *ngIf='(paidSelected || finalSelected)' color="accent"
          mat-raised-button>
    {{ 'download' | translate | ucFirst}}
    ({{(this.multiSelect['paid'].length + this.multiSelect['final'].length)}})
  </button>

  <mat-divider *ngIf="multiSelectedState==='expanded'"></mat-divider>
</div>

<table class="mat-clickable" td-data-table>
  <thead>
  <tr td-data-table-column-row>
    <th (sortChange)="sort($event)"
        *ngFor="let c of columns"
        [active]="(sortBy === c.name)"
        [name]="c.name"
        [numeric]="(!!c.numeric)"
        [sortOrder]="sortOrder"
        [sortable]="(!!c.sortable)"
        td-data-table-column>
          <span *ngIf="c.name === 'check'">
            <mat-checkbox
              (ngModelChange)="toggleMultiActionAll()"
              [(ngModel)]="allSelected">
              </mat-checkbox>
          </span>
      <span *ngIf="c.name !== 'check'">
            {{c.label|translate|ucFirst}}
          </span>
    </th>
  </tr>
  </thead>
  <tbody *ngIf="filteredData.length > 0">
  <tr *ngFor="let item of filteredData" ngClass="invoice-row" td-data-table-row>
    <td (click)="(c.name !== 'check' ?
    open(item.files.pdf) : '')" *ngFor="let c of columns" [numeric]="c.numeric" td-data-table-cell>
      <span [ngSwitch]="c.name">
        <span *ngSwitchCase="'status'"><span *ngIf="item[c.name]">{{item[c.name] | ucFirst}}</span></span>
        <span *ngSwitchCase="'displayStatus'"><span *ngIf="item[c.name]">{{item[c.name] | ucFirst}}</span></span>
        <span *ngSwitchCase="'created'"><span
          *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}</span></span>
        <span *ngSwitchCase="'amount'">
          <span *ngIf="item[c.name]" [ngClass]="(item.debit && item.total && item.total.total >= 0 ? 'debit' :
          'credit')">
            {{(item.credit ? '- ' : '')}}{{item[c.name] | localizedCurrency}}
          </span>
          <span *ngIf="!item[c.name]">-</span>
        </span>
        <span *ngSwitchCase="'check'">
          <mat-checkbox
            (ngModelChange)="toggleMultiAction(item.status, item.id)"
            [(ngModel)]="multiSelectBox[item.status][item.id]"
            [value]="item.id">
          </mat-checkbox>
        </span>
        <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
          *ngIf="!item[c.name]">-</span></span>
      </span>
    </td>
  </tr>
  <tr class="debtor-total-row custom-header-row total-column" td-data-table-row>
    <td [colSpan]="(columns.length-1)">{{'total'|translate|ucFirst}}</td>
    <td [numeric]="true" td-data-table-cell>
      {{total | localizedCurrency}}
    </td>
    <td></td>
  </tr>
  </tbody>
  <tbody *ngIf="filteredData.length === 0">
  <tr>
    <td [colSpan]="columns.length"
        class="table-placeholder">
      {{'no_invoices_in_overview'|translate|ucFirst}}
    </td>
  </tr>
  </tbody>
</table>
<td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="filteredTotal">
  <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
  {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
</td-paging-bar>
