<mat-list-item>
  <mat-icon mat-list-icon>access_time</mat-icon>
  <h4 mat-line>{{'time_data'|translate|ucFirst}}</h4>
  <p mat-line>{{'time_data_subtitle'|translate|ucFirst}}</p>

  <mat-icon (click)="this.expanded = (!this.expanded)"
            class="expand">{{(this.expanded ? 'expand_more' : 'expand_less')}}</mat-icon>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'requested_date'|translate|ucFirst}}</p>
    <p
      mat-line>{{requestedDate | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
  </mat-list-item>
  <mat-list-item *ngIf="(rideEvents && rideEvents['arrived_at_pickup']) || this.expanded" class="value small"
                 flex-gt-sm="50">
    <p mat-line>{{'arrived_at_pickup'|translate|ucFirst}}</p>
    <p *ngIf="rideEvents && rideEvents['arrived_at_pickup'] && rideEvents['arrived_at_pickup'].date"
       mat-line>{{rideEvents.arrived_at_pickup.date |
      amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
    <p *ngIf="!rideEvents || !rideEvents['arrived_at_pickup'] || !rideEvents['arrived_at_pickup'].date" mat-line>-</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'job_created_at'|translate|ucFirst}}</p>
    <p *ngIf="created"
       mat-line>{{created | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
    <p *ngIf="!created" mat-line>-</p>
  </mat-list-item>
  <mat-list-item *ngIf="(rideEvents && rideEvents['assigned']) && this.expanded" class="value small" flex-gt-sm="50">
    <p mat-line>{{'assigned_date'|translate|ucFirst}}</p>
    <p *ngIf="rideEvents && rideEvents.assigned && rideEvents.assigned.date"
       mat-line>{{rideEvents.assigned.date | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
    <p *ngIf="!rideEvents || !rideEvents.assigned || !rideEvents.assigned.date" mat-line>-</p>
  </mat-list-item>

  <mat-list-item *ngIf="status === 'canceled'" class="value small" flex-gt-sm="50">
    <p mat-line>{{'job_canceled_at'|translate|ucFirst}}</p>
    <p *ngIf="canceled"
       mat-line>{{canceled | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
    <p *ngIf="!canceled" mat-line>-</p>
  </mat-list-item>
</div>

<div *ngIf="(rideEvents && (rideEvents.started || rideEvents['arrived_at_destination'])) || this.expanded" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'pickup_date'|translate|ucFirst}}</p>
    <p *ngIf="rideEvents && rideEvents.started && rideEvents.started.date"
       mat-line>{{rideEvents.started.date | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
    <p *ngIf="!rideEvents || !rideEvents.started || !rideEvents.started.date" mat-line>-</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'dropoff_date'|translate|ucFirst}}</p>
    <p *ngIf="rideEvents && rideEvents['arrived_at_destination'] && rideEvents['arrived_at_destination'].date"
       mat-line>{{rideEvents['arrived_at_destination'].date | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
    <p *ngIf="!rideEvents || !rideEvents['arrived_at_destination'] || !rideEvents['arrived_at_destination'].date"
       mat-line>-</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="totalJobTime || this.expanded" class="value small" flex-gt-sm="50">
    <p mat-line>{{'total_job_time'|translate|ucFirst}}</p>
    <p *ngIf="totalJobTime" mat-line>{{totalJobTime}} (hh:mm:ss)</p>
    <p *ngIf="!totalJobTime" mat-line>-</p>
  </mat-list-item>

  <mat-list-item *ngIf="status === 'completed'" class="value small" flex-gt-sm="50">
    <p mat-line>{{'job_completed_at'|translate|ucFirst}}</p>
    <p *ngIf="completed"
       mat-line>{{completed | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
    <p *ngIf="!completed" mat-line>-</p>
  </mat-list-item>
</div>
