import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../services/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {DriverService} from '../../../../services/driver.service';
import {Company} from '../../../../models/company';
import {UtilityService} from '../../../../services/utility.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [DriverService]
})
export class InvoicesComponent implements OnInit, OnDestroy {
  context: string;
  company: Company;
  searchIds: string[] = [];
  companyId: string;
  dataLoaded = true;
  openInvoiceCount = 0;

  constructor(
    private _translateService: TranslateService,
    private _titleService: Title,
    private _route: ActivatedRoute,
    private _activatedRoute: ActivatedRoute,
    private _navigationService: NavigationService
  ) {
    const parentParams: any = this._activatedRoute.parent.snapshot.params;
    this.companyId = parentParams.id;
    this.searchIds.push(this.companyId);

    this.companyId = this._route.parent.snapshot.paramMap.get('id');
    this.context = 'company';
    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
    }

    _translateService.get(['company_invoices', 'invoice_settings']).subscribe((translations: any) => {
      this._titleService.setTitle(translations.company_invoices + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['company_invoices']);
      this._titleService.setTitle(translations['company_invoices']);
      this._navigationService.setActionLink('');
      this._navigationService.setSplitLayoutButtons([
        {
          'icon': 'euro_symbol',
          'tooltip': translations.company_invoices,
          'link': `/groups/${this.companyId}/debtor-invoices`
        },
        {
          'icon': 'settings',
          'tooltip': translations.invoice_settings,
          'link': `/groups/${this.companyId}/invoice-settings`
        }
      ]);
    });

    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
  }

  openInvoiceCountChanged(count: number) {
    console.log(`[InvoicesComponent.openInvoiceCountChanged]: count:`, count);
    this.openInvoiceCount = count;
  }

}
