import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {CanDeactivateGuard} from 'app/guards/can-deactivate-guard';
import {Observable} from 'rxjs/Rx';
import {Price, PriceDynamic, PriceFixed, PriceHourly, Rule} from '../../../../../../models/rule';
import {PricingRuleService} from '../../../../../../services/tps/pricing-rule.service';
import {ProductService} from '../../../../../../services/tps/product.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {DaAppInstallService} from '../../../../../../services/da-app-install.service';
import {PricingRuleLinkService} from '../../../../../../services/tps/pricing-rule-link.service';
import {NavigationService} from '../../../../../../services/navigation.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Company} from 'app/models/company';
enum Action {
  add,
  details,
}
const fromCents = (value: number) => (value / 100).toFixed(2);
const isArray = function(a) {
  return (!!a) && (a.constructor === Array);
};
@Component({
  selector: 'app-pricing-rules-upsert',
  templateUrl: './pricing-rules-upsert.component.html',
  styleUrls: ['./pricing-rules-upsert.component.scss'],
  providers: [PricingRuleService, ProductService, DaAppInstallService],
})
export class PricingRulesUpsertComponent
  implements OnInit, CanDeactivateGuard {

  ADDED_MSG = '';
  UPDATED_MSG = '';
  DELETED_MSG = '';

  action: string;
  ruleId: string;
  companyId: string;
  translations: string[];
  form: UntypedFormGroup;
  subTitle: string;
  company: Company;
  checkArrayMeter = [];
  checkArrayFixed = [];

  rule: Rule = new Rule();
  saving = false;

  private onSaveDeleteChildRules = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _pricingRuleService: PricingRuleService,
    private _pricingRuleLinkService: PricingRuleLinkService,
    private _appService: DaAppInstallService,
    private _DaAppInstallService: DaAppInstallService,
    private _productsService: ProductService,
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _snackbar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private _translateService: TranslateService,
    private _vault: CoolLocalStorage,
    private _navigationService: NavigationService
  ) {
    _translateService.get(['confirm_leave', 'unsave_changes', 'leave', 'stay',
      'error_duplicate_thresholds', 'error', 'ok', 'error_no_app_selected', 'invalid_form_check_form',
      'error_empty_dynamic', 'dispach_panel', 'passenger_multiply', 'passenger_multiply_link', 'price_rule_added', 'price_rule_updated', 'price_rule_deleted']).subscribe((translations: any) => {
      this.translations = translations;
      this.ADDED_MSG = this.translations['price_rule_added'];
      this.UPDATED_MSG = this.translations['price_rule_updated'];
      this.DELETED_MSG = this.translations['price_rule_deleted'];
    });
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const {company} = this._route.parent.snapshot.data;
    this.company = company;
  }

  /**
   * Gets the companyId, ruleId and action string from the url
   * then proceeds to prepare the form.
   */
  ngOnInit() {
    this.startLoader();
    this._route.parent.params
      .subscribe(first => {
        this.companyId = first['id'];
        this._route.params.subscribe(second => {
          this.ruleId = second['id'];
          this.action = second['action'];

          if (Action['add'] === Action[this.action]) {
            this.newData();
          } else {
            this.loadData();
          }
        });
      });
  }

  /**
   * When a child is removed, the id is saved so that it can be
   * deleted in TPS when the rule is saved.
   */
  removeChildRuleOnSave(id: string) {
    this.onSaveDeleteChildRules.push(id);
  }

  /**
   * When childRules are added to an existing rule, they mustn't be updated
   * because they have no id.
   */
  insertNewChildRules(form: UntypedFormGroup) {
    const newChildRules = form.value.childRules.filter(c => !c._id);
    return this._pricingRuleService.updateWithNewChildRules({
      _id: this.rule._id,
      childRules: newChildRules,
    });
  }

  /**
   * Guard checks whether component can be deactivated so that
   * the user doesn't accidentally navigate away from a filled
   * in form.
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form || this.form.pristine || !this.form.touched) {
      return true;
    }
    return this._dialogService.openConfirm({
      message: this.translations['confirm_leave'],
      disableClose: false,
      title: ucFirst(this.translations['unsave_changes']),
      acceptButton: ucFirst(this.translations['leave']),
      cancelButton: ucFirst(this.translations['stay']),
    }).afterClosed();
  }

  productSort = (products) => {
    return products.sort(function (a, b) {
      if (a.product.priority) {
        if (a.product.priority === b.product.priority) {
          return a.product.priority > b.product.priority ? 1 : -1;
        } else {
          return a.product.priority - b.product.priority;
        }
      } else if (a.product.maxPassengers === b.product.maxPassengers) {
        if (b.product.name.toLowerCase() === 'saloon') {
          return 1;
        } else {
          return a.product.name > b.product.name ? 1 : -1;
        }
      } else {
        return a.product.maxPassengers - b.product.maxPassengers;
      }
    });
  }

  /**
   * Generate new data for the form.
   */
  newData = () => {
    this._productsService
      .getAll({})
      .toPromise()
      .then(products => {
        const r = new Rule(this.companyId, null, products);
        this.initForm(r);
        this.form.markAsDirty();
        this.stopLoader();
      });
  }

  /**
   * Load existing data into the form.
   */
  addMissingProducts(prices, products) {
    return new Promise((resolve) => {
      if (prices.length < products.length) {
        products.forEach((p) => {
          const hasProduct = prices.filter((r) => {
            return (r.productId === p._id)
          });
          if (hasProduct.length < 1) {
            const newPrice = {
              productId: p._id,
              ruleId: this.rule._id,
              isEnabled: false,
              minuteWaitingPrice: 0,
              cascadingThresholdCalculation: false
            }
            this._pricingRuleService.insertModel(newPrice, 'Prices')
              .subscribe((nPrice) => {
                prices.push(nPrice);
                return resolve(true);
              });
          }
        });
      } else {
        return resolve(true);
      }
    });
  }

  loadData = () => {
    this._productsService
      .getAll({})
      .toPromise()
      .then(products => {
        this._pricingRuleService.getRuleTree(this.ruleId).subscribe(rules => {
            this.rule = rules[0];

            if (!this.rule.ruleLocation) {
              this.rule.ruleLocation = 'and';
            }
            const promiseArray = [];
            promiseArray.push(this.addMissingProducts(this.rule.prices, products));
            if (this.rule.childRules) {
              this.rule.childRules.forEach((rule) => {
                promiseArray.push(this.addMissingProducts(rule.prices, products));
              });
            }

            Promise.all(promiseArray)
              .then(() => {
                this.initForm(this.rule);
              })
          },
          () => {
          },
          () => this.stopLoader()
        );
      });
  }

  /**
   * Initializes form.
   */
  initForm = (rule: Rule) => {
    this.rule = rule;
    /**
     * We need to manually sort the products in the main and childRules
     */
    if (rule.prices) {
      rule.prices = this.productSort(rule.prices);
    }
    /**
     * We need to manually sort the products in the main and childRules
     */
    if (rule.childRules) {
      rule.childRules.map((r: Rule) => {
        if (r.prices) {
          r.prices = this.productSort(r.prices);
        }
      });
    }
    this.form = this.makeFormFor(rule);
    // Set validation for a location
    const updateValidators = (
      formControl: AbstractControl,
      validators: any[],
    ) => {
      formControl.setValidators(validators);
      formControl.updateValueAndValidity();
    }

    // Change validation for all location inputs depending on rule type
    const changeLocationPickerValidation = (
      form: UntypedFormGroup,
      validators: any[],
    ) => {
      updateValidators(form.controls.departureId, validators);
      updateValidators(form.controls.destinationId, validators);

      if (form.controls.childRules && form.controls.childRules['controls']) {
        form.controls.childRules['controls']
          .forEach(child =>
            changeLocationPickerValidation(child, validators));
      }
    }
    if (this.form) {
      // Watch rule type changes, then change location validators
      this.form.controls.type.valueChanges
        .subscribe(change =>
          changeLocationPickerValidation(
            this.form,
            change === 'dynamic' || change === 'hourly'
              ? null
              : [Validators.required]
          )
        );
    }
    this.form.markAsPristine();
  }

  /**
   * Persist Rule if all the checks pass.
   * @todo: this solution may be sub-optimal, see TPS-260
   */
  save = () => {
    this.checkArrayFixed = this.checkArrayFixed.filter((f) => {
      return (f)
    });

    this.checkArrayMeter = this.checkArrayMeter.filter((f) => {
      return (f)
    });

    if (this.checkArrayFixed.length === 0 && this.checkArrayMeter.length === 0) {
      this._dialogService.openAlert({
        title: this.translations['error'],
        message: this.translations['error_no_app_selected'],
        disableClose: true,
        closeButton: this.translations['ok'],
      });
      return;
    }

    if (this.saving) { return; }
    this._loadingService.register();
    this.saving = true;

    if (this.form.value.type === 'dynamic' && !this.checkIf(this.form.value.prices,
      this.noDuplicateThresholds,
      this.noZeroThresholdPrices,
      this.noZeroThresholdMetrics)
    ) {
      this._loadingService.resolve();
      this.saving = false;
      this._dialogService.openAlert({
        title: this.translations['error'],
        message: this.translations['error_duplicate_thresholds'],
        disableClose: true,
        closeButton: this.translations['ok'],
      });
    } else if (this.form.value.type === 'dynamic' &&
      !this.checkIf(this.form.value.prices, this.noZeroPrices)) {
      this._loadingService.resolve();
      this.saving = false;
      this._dialogService.openAlert({
        title: this.translations['error'],
        message: this.translations['error_empty_dynamic'],
        disableClose: true,
        closeButton: this.translations['ok'],
      });
    } else if (this.form.value.type === 'fixed' && !this.checkIf(this.form.value.prices,
      this.noZeroFixedPrices,
    )) {
      this._loadingService.resolve();
      this.saving = false;
      this._dialogService.openAlert({
        title: this.translations['error'],
        message: this._translateService.instant('error_empty_fixed'),
        disableClose: true,
        closeButton: this.translations['ok'],
      });
    } else if (this.form.value.type === 'fixed' && !this.checkIf(this.form.value.childRules,
      this.noZeroFixedChildPrices,
    )) {
      this._loadingService.resolve();
      this.saving = false;
      this._dialogService.openAlert({
        title: this.translations['error'],
        message: this._translateService.instant('error_empty_fixed'),
        disableClose: true,
        closeButton: this.translations['ok'],
      });
    } else if (this.form.value.prices.filter((p) => {
      return p.isEnabled;
    }).length < 1) {
      this._loadingService.resolve();
      this.saving = false;
      this._dialogService.openAlert({
        title: this._translateService.instant('error'),
        message: this._translateService.instant('all_products_are_disabled'),
        disableClose: true,
        closeButton: this.translations['ok'],
      });
    } else {
      if (this.form.invalid) {
        this.form.controls.childRules['controls'].forEach((childRule) => {
          childRule.controls['departureId'].markAsTouched();
          childRule.controls['departureId'].updateValueAndValidity();
          childRule.controls['destinationId'].markAsTouched();
          childRule.controls['destinationId'].updateValueAndValidity();
        });

        this._snackbar.open(this.translations['invalid_form_check_form'], '', {duration: 3000});
        this._loadingService.resolve();
        this.saving = false;
        return;
      }

      if (this.form.value.ruleLocation.touched) {
        if (this.form.value.ruleLocation === 'noLimit') {
          this.form.controls.departureId.setValue(null);
          this.form.controls.departureId.markAsDirty();
          this.form.controls.departureId.markAsTouched();
          this.form.controls.destinationId.setValue(null);
          this.form.controls.destinationId.markAsDirty();
          this.form.controls.destinationId.markAsTouched();
          this.form.value.departureId = null;
          this.form.value.destinationId = null;
        } else if (this.form.value.ruleLocation === 'either') {
          this.form.controls.departureId.markAsDirty();
          this.form.controls.departureId.markAsTouched();
          this.form.controls.destinationId.setValue(null);
          this.form.controls.destinationId.markAsDirty();
          this.form.controls.destinationId.markAsTouched();

          this.form.value.destinationId = null;
        }
      }

      if (!this.form.value.ruleLocation) {
        this.form.value.ruleLocation = 'noLimit';
      }
      if (Action[this.action] === Action['add']) {
        // On add, all data will be sent
        this.persist(this.form.value, 'insert')
        this.action = 'update';
        this.saving = false;
        this._loadingService.resolve();
      } else {
        // On details, all touched form data will be sent
        const saveData = this.getTouchedFrom(this.form);
        this.persist(saveData, 'update')
        this.insertNewChildRules(this.form).subscribe(() => {
          this.onSaveDeleteChildRules.map(id => {
            this._pricingRuleService.delete(id)
              .subscribe(count => console.warn('See @todo tag at =>'));
          })
        }, (error) => {
          this.saving = false;
          this._loadingService.resolve();
        });
        this.saving = false;
        this._loadingService.resolve();
      }
    }
  }

  /**
   * Check if provided tests pass for a given form.
   */
  checkIf = (form: UntypedFormGroup, ...tests: Function[]) =>
    tests.every(t => t(form));

  getDuplicates = (array: any[]) => array.reduce((acc, el, i, arr) => {
    if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) {
      acc.push(el);
    }
    return acc;
  }, []);

  noDuplicateThresholds = (prices: Price[]) => prices.every(p =>
    ['priceDynamic', 'priceFixed', 'priceHourly'].every(priceType =>
      ['distance', 'duration'].every(metricType => {
        return !this.getDuplicates(p[priceType].thresholds
          .filter(t => t.type === metricType)
          .map(t => t.threshold)).length
      })
    )
  );

  noZeroPrices = (prices: Price[]) => prices.every(p => {
    return (!p.isEnabled || p.priceDynamic.dynamicDistancePrice > 0 || p.priceDynamic.dynamicMinutePrice > 0 || p.priceDynamic.thresholds.length > 0)
  });

  noZeroFixedPrices = (prices: Price[]) => {
    return prices.every(p => {
      return (!p.isEnabled || (p.priceFixed && p.priceFixed.fixedPrice > 0));
    });
  };

  noZeroFixedChildPrices = (rule: Rule[]) => {
    return rule.every(r => {
      const prices = r.prices;
      return prices.every(p => {
        return (!p.isEnabled || (p.priceFixed && p.priceFixed.fixedPrice > 0));
      });
    });
  };

  noZeroThresholdPrices = (prices: Price[]) => prices.every(p =>
    p.priceDynamic.thresholds.every(t => t.value > 0)
    && p.priceHourly.thresholds.every(t => t.value > 0)
    && p.priceFixed.thresholds.every(t => t.value > 0));

  noZeroThresholdMetrics = (prices: Price[]) => prices.every(p =>
    p.priceDynamic.thresholds.every(t => t.threshold > 0)
    && p.priceHourly.thresholds.every(t => t.threshold > 0)
    && p.priceFixed.thresholds.every(t => t.threshold > 0));

  /**
   * Persist data.
   */
  persist = (data: any, method: 'update' | 'insert') => {
    this._pricingRuleService[method](data)
      .subscribe((result) => {
          if (!this.form.value._id) {
            this.form.markAsPristine();
            this.form.controls._id.setValue(result._id);
            this.ruleId = result._id;
          }
          this.saveRules(this.checkArrayMeter, this.checkArrayFixed, this.form.value)
            .then(() => {
              this._snackbar.open((method === 'insert' ? this.ADDED_MSG : this.UPDATED_MSG), '', {duration: 3000});
              this.form.markAsPristine();
              this._router.navigate([`/groups/${this.companyId}/pricing/rules/${this.ruleId}/details`]);
            })
        },

        () => this.reload(),

        () => {
          this.stopLoader();
        }
      );
  }

  /**
   * Delete Rule.
   */
  delete = () =>
    this._dialogService
      .openConfirm({
        message: 'Are you sure you wish to delete this rule?',
        title: 'Delete rule',
        acceptButton: 'Delete',
        cancelButton: 'Cancel',
        disableClose: true,
      })
      .afterClosed()
      .subscribe(
        confirm => {
          if (!confirm) {
            return;
          }
          this.startLoader();
          this._pricingRuleService
            .delete(this.ruleId)
            .subscribe(
              () => {
                this._snackbar.open(this.DELETED_MSG, '', {duration: 3000});
                this._router.navigate([
                  `/groups/${this.companyId}/pricing/rules`
                ]);
              }
            );
        },
        () => this.reload(),
        () => this.stopLoader()
      );

  /**
   * Checks if form is touched, then checks if properties are touched,
   * then collect everything that is touched.
   */
  getTouchedFrom = (form: UntypedFormGroup | UntypedFormArray, f = null) => {
    // Base case, just the value
    if (!form.controls) {
      return form.value;
    }

    const touched = {};
    // Something may have changed somewhere down the tree
    if (form.touched) {
      // Add id if present
      if (form.controls['_id']) {
        touched['_id'] = form.controls['_id'].value;
      }
      // Iterate keys and see which of them are dirty recursively
      // tslint:disable-next-line:no-shadowed-variable
      for (const f of Object.keys(form.controls)) {
        // If not dirty, continue
        if (form.controls[f].pristine) {
          continue;
        }
        // Else, get touched recursively
        touched[f] = this.getTouchedFrom(form.controls[f], f);

        if (f === 'thresholds' || f === 'passengerPercentage') {
          touched[f] = form.controls[f].value;
        }
      }
    }
    return touched;
  }

  /**
   * Makes a form for a given rule.
   */
  makeFormFor = (rule: Rule): UntypedFormGroup => {
    const minZeroValidator = [Validators.required, Validators.min(0)];
    const locationValidator = this.form && this.form.value.type === 'fixed'
      ? [Validators.required]
      : null;
    let prices = [];
    if (rule.prices) {
      prices = rule.prices.map((price) => {
        const ruleProduct = price.product;

        const product = this._fb.group({
          _id: [ruleProduct._id],
          name: [ruleProduct.name],
          type: [ruleProduct.type],
          imagePath: [ruleProduct.imagePath],
          maxPassengers: [ruleProduct.maxPassengers],
        });
        let priceDynamic;
        if (price && !price.priceDynamic) {
          console.log('Missing priceDynamic, init recovery');
          console.log(price);
          price.priceDynamic = new PriceDynamic();
          price.priceDynamic.priceId = price._id;
          this._pricingRuleService.insertModel(price.priceDynamic, 'PricesDynamic').subscribe((result) => {
          });
        }

        if (price && price.priceDynamic) {
          priceDynamic = this._fb.group({
            _id: [price.priceDynamic._id],
            dynamicStartPrice:
              [fromCents(price.priceDynamic.dynamicStartPrice), minZeroValidator],
            dynamicMinimumPrice:
              [fromCents(price.priceDynamic.dynamicMinimumPrice), minZeroValidator],
            dynamicMinutePrice:
              [fromCents(price.priceDynamic.dynamicMinutePrice), minZeroValidator],
            dynamicDistancePrice:
              [fromCents(price.priceDynamic.dynamicDistancePrice), minZeroValidator],
            thresholds: this._fb.array(
              price.priceDynamic.thresholds.map(
                threshold => this._fb.group({
                  _id: [threshold._id],
                  type: [threshold.type, [Validators.required]],
                  threshold: [threshold.threshold, [Validators.required, Validators.min(1)]],
                  value:
                    [fromCents(threshold.value), minZeroValidator],
                })
              )
            ),
          });
        }
        let priceFixed;
        if (price && !price.priceFixed) {
          console.log('Missing priceFixed, init recovery');
          console.log(price);
          price.priceFixed = new PriceFixed();
          price.priceFixed.priceId = price._id;
          delete price.priceFixed._id;
          this._pricingRuleService.insertModel(price.priceFixed, 'PricesFixed').subscribe((result) => {
            window.location.reload();
          });
        }
        if (price && price.priceFixed) {
          priceFixed = this._fb.group({
            _id: [price.priceFixed._id],
            fixedPrice:
              [fromCents(price.priceFixed.fixedPrice), minZeroValidator],
            thresholds: this._fb.array(
              price.priceFixed.thresholds.map(threshold => this._fb.group({
                _id: [threshold._id],
                type: [threshold.type, [Validators.required]],
                threshold: [threshold.threshold, [Validators.required, Validators.min(1)]],
                value: [threshold.value, minZeroValidator],
              }))
            ),
          });
        }
        let priceHourly;
        if (price && !price.priceHourly) {
          console.log('Missing priceHourly, init recovery');
          console.log(price);
          if (rule.prices[0] && rule.prices[0].priceHourly && rule.type === 'hourly') {
            price.priceHourly = rule.prices[0].priceHourly;
          } else {
            price.priceHourly = new PriceHourly();
          }
          price.priceHourly.priceId = price._id;
          delete price.priceHourly._id;
          this._pricingRuleService.insertModel(price.priceHourly, 'PricesHourly').subscribe((result) => {
            window.location.reload();
          });
        }

        if (price && price.priceHourly) {
          priceHourly = this._fb.group({
            _id: [price.priceHourly._id],
            hourlyStartPrice:
              [fromCents(price.priceHourly.hourlyStartPrice), minZeroValidator],
            hourlyMinimumPrice:
              [fromCents(price.priceHourly.hourlyMinimumPrice), minZeroValidator],
            hourlyPrice:
              [fromCents(price.priceHourly.hourlyPrice), minZeroValidator],
            thresholds: this._fb.array(
              price.priceHourly.thresholds.map(
                threshold => this._fb.group({
                  _id: [threshold._id],
                  type: [threshold.type, [Validators.required]],
                  threshold: [threshold.threshold, [Validators.required, Validators.min(1)]],
                  value:
                    [fromCents(threshold.value), minZeroValidator],
                })
              )
            ),
          });
        }

        return this._fb.group({
          '_id': [price._id],
          'isEnabled': [price.isEnabled],
          'minuteWaitingPrice': [fromCents(price.minuteWaitingPrice), Validators.required],
          'cascadingThresholdCalculation': [price.cascadingThresholdCalculation, [Validators.required]],
          'priceDynamic': (priceDynamic ? priceDynamic : null),
          'priceFixed': (priceFixed ? priceFixed : null),
          'priceHourly': (priceHourly ? priceHourly : null),
          'productId': [price.productId],
          product,
        });
      });

      const timeframes = this._fb.array(
        rule.timeframes.map(timeframe => this._fb.group({
          _id: [timeframe._id],
          startDate: [timeframe.startDate, [Validators.required]],
          endDate: [timeframe.endDate, [Validators.required]],
          weekSchedule: [timeframe.weekSchedule,
            [Validators.required, Validators.pattern(/^[0,1]{168}/)]],
          isSpecificWeekDays: [timeframe.isSpecificWeekDays, [Validators.required]],
        }))
      );

      return this._fb.group({
        _id: [rule._id],
        name: [rule.name, [Validators.required]],
        type: [rule.type, [Validators.required]],
        taxiMeter: [(rule.taxiMeter ? rule.taxiMeter : false), []],
        ruleLocation: [(rule.ruleLocation ? rule.ruleLocation : 'noLimit'), []],
        passengerMultiply: [(rule.passengerMultiply ? rule.passengerMultiply : false), []],
        passengerPercentage: this._fb.array((rule.passengerPercentage ? rule.passengerPercentage : [100, 100, 100, 100, 100, 100, 100, 100])),
        priority: [rule.priority, [Validators.required, Validators.min(1)]],
        prices: this._fb.array(prices),
        destinationId: [rule.destinationId, locationValidator],
        departureId: [rule.departureId, locationValidator],
        companyId: [rule.companyId],
        timeframes: timeframes,
        // Recursively makes forms for child rules
        childRules: rule.childRules
          ? this._fb.array(rule.childRules.map(this.makeFormFor))
          : []
      });
    }
  }

  /**
   * Reload data on page when error occurs.
   */
  reload = () => {
    this.form.markAsDirty();
    this.loadData();
  }

  /**
   * Start the spinning loader.
   */
  startLoader = () => this._loadingService.register('rule')

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => this._loadingService.resolve('rule');

  updateCheckArrayMeter(e) {
    this.checkArrayMeter = e.checkArrayMeter;
    if (!e.loading) {
      this.form.markAsDirty();
    }
  }

  updateCheckArrayFixed(e) {
    this.checkArrayFixed = e.checkArrayFixed;
    if (!e.loading) {
      this.form.markAsDirty();
    }
  }

  saveRules(meteredArray, fixedArray, rule): Promise<void> {
    return new Promise((resolve) => {
      this._vault.removeItem(`${this.companyId}_hasBaseRule`)

      const mergedArray = meteredArray.concat(fixedArray);
      this._appService.getAll({where: {companyId: this.companyId}})
        .subscribe((installs) =>  {
          installs.forEach((i, cnt) => {
            this._pricingRuleLinkService.getAll({
              where: {
                'ruleableType': 'DaAppInstall',
                'ruleableId': i.id,
                'ruleId': rule._id
              }
            }).subscribe((r: any) => {
              r = r[0];
              if (r) {
                if (!meteredArray.includes(i.id) && !fixedArray.includes(i.id)) {
                  this._pricingRuleLinkService.delete(r._id, 'company').toPromise();
                } else {
                  this._pricingRuleLinkService.update(r._id, {
                    'isAllowedOnMeter': (meteredArray.includes(i.id)),
                    'isFixed': (fixedArray.includes(i.id)),
                  }).toPromise();
                }
              } else if (fixedArray.includes(i.id)) {
                this._pricingRuleLinkService.insert({
                  'isAllowedOnMeter': (meteredArray.includes(i.id)),
                  'isFixed': (fixedArray.includes(i.id)),
                  'ruleableType': 'DaAppInstall',
                  'ruleableId': i.id,
                  'ruleId': rule._id,
                  'companyId': rule.companyId
                }).toPromise();
              }

              if (cnt === (installs.length - 1)) {
                return resolve();
              }
            });
          })
        })
    });
  }

  setDefaultPassengerPercentage() {
    this.form.controls.passengerPercentage.markAsTouched();
    this.form.controls.passengerPercentage.markAsDirty();
  }

  /**
   * See if form should be disabled.
   */
  private formDisabled = (): boolean =>
    !this.form
    || !this.form.dirty
    || !this.form.valid;
}
